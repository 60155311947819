import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { apiUrl } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  response:any;
  apiLink = apiUrl+'/api/';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toastr: ToastrService
  ) { }

  async getAllSurveys(filter = {}) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'survey/get_all', {filter: filter}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.surveys;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return true;
    });
  }

  async getSurveyDetails(surveyId) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'survey/get_details', {id: surveyId}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return this.response.survey;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }

  async getAllSurveyData(surveyDetails:any, filter = {}) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'survey/get_all_data', {form_id:surveyDetails.id, filter:filter}, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }
      return {
        formdata: this.response.formdatas,
        totalCount: this.response.total_count
      };
    });
  }

  async addSurvey(surveyDetails:any) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'survey/add', surveyDetails, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return true;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return true;
    });
  }

  async updateSurvey(surveyDetails:any) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink+'survey/update', surveyDetails, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return true;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return true;
    });
  }

  async updateLocation(surveyDetails:any, location:number) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    let updateDetails = {
      survey_id: surveyDetails.id,
      location: location
    }
    return this.http.post(this.apiLink+'survey/update_location', updateDetails, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return true;
    });
  }

  async updateIsPublic(surveyDetails:any, is_public:number) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    let updateDetails = {
      survey_id: surveyDetails.id,
      is_public: is_public
    }
    return this.http.post(this.apiLink+'survey/update_is_public', updateDetails, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return {public_id: this.response.public_id};
    });
  }

  async submitSurvey(surveyData, surveyDetails, beneficiary, status, locDetails = {}) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    let data = {
      locDetails: locDetails,
      form_id: surveyDetails.id,
      user_id: userDetails.user.id,
      form_data: JSON.stringify(surveyData),
      form_data_status: status,
      beneficiary_id: beneficiary
    }
    return this.http.post(this.apiLink+'survey/collect', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return this.response.dataId;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return true;
    });
  }

  //===========enable survey=================
  async enableSurveys(surveyId) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink + 'survey/enable', {surveyId : surveyId}, { headers: headers }).toPromise().then(response => {
      this.response = response;
      if (this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        return true;
      }
    });
  }

  //===========disable survey=================
  async disableSurveys(surveyId) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink + 'survey/disable', {surveyId : surveyId}, { headers: headers }).toPromise().then(response => {
      this.response = response;
      if (this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        return true;
      }
    });
  }

  async updateSurveyData(surveyData, data_id, beneficiary, status) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    let data = {
      data_id: data_id,
      form_data: JSON.stringify(surveyData),
      form_data_status: status,
      beneficiary_id: beneficiary
    }
    return this.http.post(this.apiLink+'survey/update_data', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return this.response.dataId;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return true;
    });
  }

  async deleteSurveyData(data_id) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';
    
    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    let data = {
      data_id: data_id
    }
    return this.http.post(this.apiLink+'survey/delete_data', data, {headers: headers}).toPromise().then(response => {
      this.response = response;
      if(this.response.status == 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      }

      this.toastr.success(this.response.msg, 'Success!', {
        timeOut: 5000,
      });
      return true;
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return true;
    });
  }

  //===========import survey=================
  async importSurveyData(survey, jsonData) {
    let userDetails = await this.authService.getUserDetails(),
    token = userDetails.token ? userDetails.token : '';

    // Create header and append JWT token
    let headers = new HttpHeaders({
      'Authorization': `Bearer: ${token}`
    });
    headers.append('Authorization', token);

    return this.http.post(this.apiLink + 'survey/import', {surveyId : survey.id, jsonData: jsonData}, { headers: headers }).toPromise().then(response => {
      this.response = response;
      if (this.response.status === 0) {
        this.toastr.error(this.response.msg, 'Error!', {
          timeOut: 5000,
        });
        return false;
      } else {
        this.toastr.success(this.response.msg, 'Success!', {
          timeOut: 5000,
        });
        return true;
      }
    }).catch(err => {
      console.log(err);
      this.toastr.error('Could not connect to server. Please check your network connection.', 'Network Error!', {
        timeOut: 5000,
      });
      return false;
    });
  }
}
